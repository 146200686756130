import React from "react";
import { Row, Col } from "react-bootstrap";

//STYLES
import * as FooterStyles from "./styles";

function Footer() {
    return (
        <div style={FooterStyles.body}>
            <div className="titleFont" style={FooterStyles.title}>
                St Giles
            </div>
            <br />
            <div style={FooterStyles.linksSection}>
                <div style={FooterStyles.innerLinksSection}>
                    <Row>
                        <Col style={FooterStyles.link}>ABOUT</Col>
                        <Col style={FooterStyles.link}>EVENTS</Col>
                        <Col style={FooterStyles.link}>SERVICES</Col>
                        <Col style={FooterStyles.link}>CONTACT US</Col>
                    </Row>
                </div>
            </div>
            <br />
            <div style={FooterStyles.produced}>Produced by High-View Studios</div>
        </div>
    );
}

export default Footer;
