import * as Colors from "../../../../../../../../globalStyles/administratorColors";

export const tag = {
    position: "absolute",
    backgroundColor: Colors.primary,
    padding: "5px",
    color: "white",
    borderRadius: "0 0 10px 0",
    zIndex: "1"
};

export const section = {
    borderBottom: "1px solid black"
};
