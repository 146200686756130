import React, { useState, useEffect } from "react";

//COMPONENTS
import NavBar from "../../mainStyle/components/navBar/navBar";
import HomeSection from "../../mainStyle/components/homeSections/home";
import MeetTheTeam from "../../mainStyle/components/homeSections/meetTheTeam";
import ServicesAndEvents from "../../mainStyle/components/homeSections/servicesAndEvents";
import Community from "../../mainStyle/components/homeSections/community";
import Links from "../../mainStyle/components/homeSections/links";
import ContactUs from "../../mainStyle/components/homeSections/contactUs";

//STYLES
import * as HomeStyles from "../styles/home";

function Home() {
    const [section, setSection] = useState(1);
    const [mouseDirection, setMouseDirection] = useState("null");

    const [sitePosition, setSitePosition] = useState(0);

    let mouseX = 0;
    let mouseY = 0;

    let initialX = 0;
    let initialY = 0;

    let isSwpied = false;

    function MovePosition(event) {
        let num = event.view.id;
        if (event.deltaY < 0) {
            num = num + 100;
            if (num > 0) {
                num = num - 100;
            }
        } else {
            num = num - 100;
            if (num < -500) {
                num = num + 100;
            }
        }
        setSitePosition(num);
        console.log(num);
        setTimeout(AddEvent, 1500, num);
    }

    function AddEvent(num) {
        window.id = num;
        window.addEventListener("wheel", MovePosition, { once: true });
    }

    useEffect(() => {
        document.body.style.overflow = "hidden";
        console.log("ININ");
        setTimeout(AddEvent, 10, 0);
        // const mainFrame = document.getElementById("mainFrame");
        // let reactLeft = mainFrame.getBoundingClientRect().left;
        // let reactTop = mainFrame.getBoundingClientRect().top;
        // const getXY = (e) => {
        //     mouseX = e.pageX;
        //     mouseY = e.pageY;
        // };
        // mainFrame.addEventListener("mousedown", (event) => {
        //     isSwpied = true;
        //     getXY(event);
        //     initialX = mouseX;
        //     initialY = mouseY;
        // });
        // mainFrame.addEventListener("mousemove", (event) => {
        //     event.preventDefault();
        //     if (isSwpied) {
        //         getXY(event);
        //         let diffX = mouseX - initialX;
        //         let diffY = mouseY - initialY;
        //         if (Math.abs(diffY) > Math.abs(diffX)) {
        //             console.log(diffY > 0 ? "Down" : "Up");
        //         }
        //     }
        // });
        // mainFrame.addEventListener("mouseup", (event) => {
        //     isSwpied = false;
        // });
        // mainFrame.addEventListener("mouseleave", (event) => {
        //     isSwpied = false;
        // });
    }, []);

    function UpdatePageFromNav(position) {
        setSitePosition(position);
        window.id = position;
    }

    return (
        <div id="mainFrame">
            <div style={HomeStyles.navBar}>
                <NavBar setSitePosition={UpdatePageFromNav} />
            </div>
            <div style={{ ...HomeStyles.bodies, top: `${sitePosition}vh` }}>
                <HomeSection />
            </div>
            <div style={{ ...HomeStyles.bodies, top: `${sitePosition + 100}vh` }}>
                <MeetTheTeam />
            </div>
            <div style={{ ...HomeStyles.bodies, top: `${sitePosition + 200}vh` }}>
                <ServicesAndEvents />
            </div>
            <div style={{ ...HomeStyles.bodies, top: `${sitePosition + 300}vh` }}>
                <Community />
            </div>
            <div style={{ ...HomeStyles.bodies, top: `${sitePosition + 400}vh` }}>
                <Links />
            </div>
            <div style={{ ...HomeStyles.bodies, top: `${sitePosition + 500}vh` }}>
                <ContactUs />
            </div>
        </div>
    );
}

export default Home;
