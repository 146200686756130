import React, { useEffect } from "react";
import { Row, Col, Button, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

//ACTIONS
import * as GS_navSettingsActions from "../../../../../../../../../store/actions/globalSettings/GS_navSettings";

//STYLES
import * as ControllerStyles from "../styles/styleMenu";

function Controller() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GS_navSettingsActions.UpdateTitle("Style Configuration"));
        dispatch(GS_navSettingsActions.UpdateSelected("Style Configuration"));
    }, []);

    const styles = [
        {
            id: 1,
            title: "Slider",
            key: "slider"
        },
        {
            id: 2,
            title: "Introduction",
            key: "simpleSectionCentredText"
        },
        {
            id: 3,
            title: "Half Photo and Article",
            key: "halfPhoto"
        },
        {
            id: 4,
            title: "Four Sections",
            key: "fourSectionsOneRow"
        },
        {
            id: 5,
            title: "Banner",
            key: "bannerSections"
        },
        {
            id: 6,
            title: "Reviews",
            key: "reviewsInColumns"
        },
        {
            id: 7,
            title: "Introductions row",
            key: "photoRow"
        }
    ];

    function handleStyleLinkOnClick(key) {
        navigate(`./${key}`);
    }

    function handleViewDesignOnClick() {
        window.open("./styleConfiguration/design", "_blank");
    }

    return (
        <div style={ControllerStyles.body}>
            <div style={ControllerStyles.columnRight}>
                <Button onClick={handleViewDesignOnClick}>View Design</Button>
            </div>
            <Row>
                <Col style={ControllerStyles.columnRight}></Col>
            </Row>
            <br />
            <Row>
                <Col>
                    {styles.map((style, index) => {
                        return (
                            <div key={index}>
                                <Card style={ControllerStyles.cardStyle} onClick={handleStyleLinkOnClick.bind(this, style.key)}>
                                    <Card.Body>
                                        <Row>
                                            <Col>{`Section ${style.id}: ${style.title}`}</Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                                <br />
                            </div>
                        );
                    })}
                </Col>
            </Row>
        </div>
    );
}

export default Controller;
