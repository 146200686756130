import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Form, Button } from "react-bootstrap";
import Axios from "axios";

//ACTIONS
import * as GS_navSettingsActions from "../../../../../../../../../../store/actions/globalSettings/GS_navSettings";

//STYLES
import * as ConfigStyles from "./styles";

function Config() {
    const dispatch = useDispatch();
    const [text, setText] = useState("");
    const [buttonColor, setButtonColor] = useState("primary");

    const maxLength = 800;

    useEffect(() => {
        dispatch(GS_navSettingsActions.UpdateTitle("Style Configuration - Introduction"));

        Axios.post("/style/simpleSectionCenteredText/load")
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setText(data.text);
                }
            })
            .catch((err) => console.log(err));
    }, []);

    function handleTextChange(event) {
        const { value } = event.target;

        setText(value);
    }

    function handleOnUpdate() {
        const data = { text: text };
        Axios.post("/style/simpleSectionCenteredText/update", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setButtonColor("success");
                    setTimeout(() => {
                        setButtonColor("primary");
                    }, 2000);
                }
            })
            .catch((err) => console.log(err));
    }

    return (
        <div style={ConfigStyles.body}>
            <h3>Text: </h3>
            <Form.Control as="textarea" rows={3} maxLength={maxLength} value={text} onChange={handleTextChange} />
            <div style={ConfigStyles.counter}>
                {text.length}/{maxLength}
            </div>
            <br />
            <div style={ConfigStyles.columnRight}>
                <Button style={ConfigStyles.updateButton} variant={buttonColor} onClick={handleOnUpdate}>
                    Update
                </Button>
            </div>
        </div>
    );
}

export default Config;
