export const body = {
    width: "100vw",
    height: "75px",
    position: "fixed",
    top: "0",
    left: "0",
    transition: "all 0.5s ease-out",
    zIndex: "2"
};

export const logoContainer = {
    padding: "10px",
    paddingLeft: "30px"
};

export const logo = {
    width: "50px",
    height: "60px",
    color: "white"
};

export const buttonContainer = {
    color: "white",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontWeight: "bold"
    // border: "solid 1px black"
};

export const menuCover = {
    backgroundColor: "#FAF3F3",
    borderBottom: "3px solid #DA7F8F"
};

export const subMenuItem = {
    height: "50px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: "20px",
    cursor: "pointer"
};

export const subMenuItemHover = {
    height: "50px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: "20px",
    cursor: "pointer",
    fontWeight: "bold"
};

export const triangle = {
    width: "0",
    height: "0",
    border: "10px solid transparent",
    borderTop: "0",
    borderBottom: "20px solid #FAF3F3",
    position: "relative",
    top: "-20px",
    left: "20px"
};

export const menuTriangle = {
    transition: "all 0.5s ease-out",
    width: "0",
    height: "0",
    border: "5px solid transparent",
    borderTop: "5px solid white",
    borderBottom: "0",
    position: "relative",
    left: "5px"
};

export const menu = {
    transition: "transform 0.1s",
    position: "relative",
    top: "10px",
    left: "10px",
    zIndex: "2",
    width: "200px"
};
