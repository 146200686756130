export const body = {
    height: "300px",
    backgroundColor: "#FAF3F3",
    display: "flex",
    flexDirection: "column",
    flex: "1",
    alignItems: "center",
    justifyContent: "center"
};

export const outerSection = {
    width: "70%",
    height: "80%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
};

export const slideshowContainer = {
    overflow: "hidden",
    border: "3px solid red",
    height: "100%",
    position: "relative"
    // zIndex: "3"
};

//EVENT SLOT

export const eventSlot = {
    height: "100%",
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    flex: "1",
    alignItems: "center",
    justifyContent: "center"
};

export const smallContainer = {
    // border: "1px solid black",
    height: "20%",
    width: "80%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
};

export const imageContainer = {
    // border: "1px solid black",
    height: "60%",
    width: "80%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
};

export const image = {
    width: "80%",
    height: "80%"
};
