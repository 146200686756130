import React from "react";
import { Row, Col, Image, Button } from "react-bootstrap";

import StrainGlassWindow from "../../public/images/stainGlass2.jpeg";

//STYLES
import * as HomeStyles from "./styles/home";

function Home(props) {
    return (
        <div>
            <Row>
                <Col style={HomeStyles.cardNav}>
                    <div style={HomeStyles.cardNavInner}>
                        <Image src={StrainGlassWindow} style={HomeStyles.cardNavImage} />
                        <div style={HomeStyles.cardNavText}>Item</div>
                    </div>
                </Col>
                <Col style={HomeStyles.cardNav}>
                    <div style={HomeStyles.cardNavInner}>
                        <Image src={StrainGlassWindow} style={HomeStyles.cardNavImage} />
                        <div style={HomeStyles.cardNavText}>Item</div>
                    </div>
                </Col>
                <Col style={HomeStyles.cardNav}>
                    <div style={HomeStyles.cardNavInner}>
                        <Image src={StrainGlassWindow} style={HomeStyles.cardNavImage} />
                        <div style={HomeStyles.cardNavText}>Item</div>
                    </div>
                </Col>
                <Col style={HomeStyles.cardNav}>
                    <div style={HomeStyles.cardNavInner}>
                        <Image src={StrainGlassWindow} style={HomeStyles.cardNavImage} />
                        <div style={HomeStyles.cardNavText}>Item</div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col style={HomeStyles.textAside}>
                    Go to our special Image Gallery <Button>Click here</Button>
                </Col>
                <Col style={HomeStyles.pictureHolder}>
                    <Image src={StrainGlassWindow} style={HomeStyles.glassImage} />
                </Col>
            </Row>
        </div>
    );
}

export default Home;
