import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Form, Button, Modal } from "react-bootstrap";
import Axios from "axios";
import { useNavigate } from "react-router-dom";

//ACTIONS
import * as GS_navSettingsActions from "../../../../../store/actions/globalSettings/GS_navSettings";

//STYLES
import * as StyleLoginStyles from "../styles/styleLogin";

function StyleLogin() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        values: {
            name: "",
            email: "",
            tag: ""
        },
        valids: {
            name: false,
            email: false,
            password: false,
            confirmPassword: false,
            tag: false
        },
        touched: {
            name: false,
            email: false,
            password: false,
            confirmPassword: false,
            tag: false
        },
        formValid: false
    });

    const modalDefaults = {
        open: false,
        header: "",
        message: ""
    };

    const [modal, setModal] = useState(modalDefaults);

    function handleCloseModal() {
        setModal(modalDefaults);
    }

    useEffect(() => {
        dispatch(GS_navSettingsActions.UpdateTitle("Styling Portal - New Style Login"));
        dispatch(GS_navSettingsActions.UpdateSelected("Features"));
        dispatch(GS_navSettingsActions.UpdateSubSelected("Styling Portal"));

        onLoad();
    }, []);

    function onLoad() {}

    function handleOnTouched(event) {
        const { name } = event.target;

        const touches = { ...formData.touched };

        touches[name] = true;

        setFormData((prevState) => {
            return { ...prevState, touched: touches };
        });
    }

    function handleNormalTextChange(event) {
        const { name, value } = event.target;

        const values = { ...formData.values };
        const valids = { ...formData.valids };

        let isValid = true;
        if (value.length === 0) {
            isValid = false;
        }

        if (name === "email" && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/gm.test(value)) {
            isValid = false;
        }

        values[name] = value;
        valids[name] = isValid;

        const formValid = Object.keys(valids).every(function (k) {
            return valids[k] === true;
        });

        setFormData((prevState) => {
            return { ...prevState, values, valids, formValid };
        });
    }

    function handlePasswordTextChange(event) {
        const { name, value } = event.target;

        const valids = { ...formData.valids };

        let isValid = true;
        if (value.length === 0) {
            isValid = false;
        }

        valids[name] = isValid;

        const formValid = Object.keys(valids).every(function (k) {
            return valids[k] === true;
        });

        setFormData((prevState) => {
            return { ...prevState, valids, formValid };
        });
    }

    function switchAllTouches() {
        const newTouches = Object.assign(...Object.keys(formData.touched).map((k) => ({ [k]: true })));
        setFormData((prevState) => {
            return { ...prevState, touched: newTouches };
        });
    }

    function handleAddClick() {
        if (!formData.formValid) {
            switchAllTouches();
        } else {
            const password = document.getElementById("password").value;
            const confirmPassword = document.getElementById("confirmPassword").value;

            if (password != confirmPassword) {
                setModal({ header: "Password", message: "Your passwords do not match!", open: true });
            } else {
                const data = {
                    name: formData.values.name,
                    email: formData.values.email,
                    tag: formData.values.tag,
                    password: password
                };
                Axios.post("/pods/stylingPortalAdmin/addStyleLogin", data)
                    .then((res) => {
                        const data = res.data;
                        if (data.error == "Yes") {
                            setModal({ header: "Error!", message: data.message, open: true });
                        } else {
                            navigate(-1);
                        }
                    })
                    .catch((err) => console.log(err));
            }
        }
    }

    return (
        <div style={StyleLoginStyles.body}>
            <Row>
                <Col>
                    <Form.Group>
                        <Form.Label>Name:</Form.Label>
                        <Form.Control
                            isInvalid={formData.touched.name && !formData.valids.name ? true : false}
                            type="text"
                            name="name"
                            value={formData.values.name}
                            onChange={handleNormalTextChange}
                            onBlur={handleOnTouched}
                        />
                        <Form.Control.Feedback type="invalid">Please enter a name!</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label>Email:</Form.Label>
                        <Form.Control
                            isInvalid={formData.touched.email && !formData.valids.email ? true : false}
                            type="text"
                            name="email"
                            value={formData.values.email}
                            onChange={handleNormalTextChange}
                            onBlur={handleOnTouched}
                        />
                        <Form.Control.Feedback type="invalid">Please enter a valid email</Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            <br />
            <Row>
                <Col>
                    <Form.Group>
                        <Form.Label>Password:</Form.Label>
                        <Form.Control
                            isInvalid={formData.touched.password && !formData.valids.password ? true : false}
                            type="password"
                            name="password"
                            id="password"
                            value={formData.values.password}
                            onChange={handlePasswordTextChange}
                            onBlur={handleOnTouched}
                        />
                        <Form.Control.Feedback type="invalid">Please enter a password!</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label>Confirm Password:</Form.Label>
                        <Form.Control
                            isInvalid={formData.touched.confirmPassword && !formData.valids.confirmPassword ? true : false}
                            type="password"
                            name="confirmPassword"
                            id="confirmPassword"
                            value={formData.values.confirmPassword}
                            onChange={handlePasswordTextChange}
                            onBlur={handleOnTouched}
                        />
                        <Form.Control.Feedback type="invalid">Please confirm your password!</Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            <br />
            <Row>
                <Col>
                    <Form.Group>
                        <Form.Label>Tag:</Form.Label>
                        <Form.Control
                            isInvalid={formData.touched.tag && !formData.valids.tag ? true : false}
                            type="text"
                            name="tag"
                            value={formData.values.tag}
                            onChange={handleNormalTextChange}
                            onBlur={handleOnTouched}
                        />
                        <Form.Control.Feedback type="invalid">Please enter a tag!</Form.Control.Feedback>
                    </Form.Group>
                    <br />
                </Col>
                <Col style={StyleLoginStyles.columnRight}>
                    <Button onClick={handleAddClick}>Add</Button>
                </Col>
            </Row>
            <Modal show={modal.open} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{modal.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseModal}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default StyleLogin;
