import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Button, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Axios from "axios";

//ACTIONS
import * as GS_navSettingsActions from "../../../../../store/actions/globalSettings/GS_navSettings";

//STYLES
import * as ControllerStyles from "../styles/controller";

function Controller() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [logins, setLogins] = useState([]);

    useEffect(() => {
        dispatch(GS_navSettingsActions.UpdateTitle("Styling Portal"));
        dispatch(GS_navSettingsActions.UpdateSelected("Features"));
        dispatch(GS_navSettingsActions.UpdateSubSelected("Styling Portal"));

        onLoad();
    }, []);

    function onLoad() {
        Axios.post("/pods/stylingPortalAdmin/getAllStyleLogins")
            .then((res) => {
                const data = res.data;
                console.log(data);
                if (data.error == "null") {
                    setLogins(data.logins);
                }
            })
            .catch((err) => console.log(err));
    }

    function handleNewStyleLoginClicked() {
        navigate("./newLogin");
    }

    return (
        <div style={ControllerStyles.body}>
            <Row>
                <Col style={ControllerStyles.columnRight}>
                    <Button onClick={handleNewStyleLoginClicked}>New Style Login</Button>
                </Col>
            </Row>
            <br />
            <Row>
                <Col>
                    {logins.map((login, index) => {
                        return (
                            <div key={index}>
                                <Card style={ControllerStyles.cardStyle}>
                                    <Card.Body>
                                        <Row>
                                            <Col>{login.user}</Col>
                                            <Col>{login.tag}</Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                                <br />
                            </div>
                        );
                    })}
                </Col>
            </Row>
        </div>
    );
}

export default Controller;
