import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Image, Button, Form } from "react-bootstrap";
import Axios from "axios";

//ACTIONS
import * as GS_navSettingsActions from "../../../../../../../../../../store/actions/globalSettings/GS_navSettings";

//STYLES
import * as ConfigStyles from "./styles";

//COMPONENT
import UploadComponent from "../../uploadComponent/config";

function Config() {
    const dispatch = useDispatch();
    const [image, setImage] = useState("");
    const [text, setText] = useState({
        values: {
            title: "",
            content: ""
        },
        maxLength: {
            title: 50,
            content: 400
        }
    });
    const [buttonColor, setButtonColor] = useState("primary");

    useEffect(() => {
        dispatch(GS_navSettingsActions.UpdateTitle("Style Configuration - Reviews"));

        onLoad();
    }, []);

    function onLoad() {
        Axios.post("/style/reviewsInColumns/load")
            .then((res) => {
                const data = res.data;
                console.log(data);
                if (data.error == "null") {
                    const values = {
                        title: data.info.title,
                        content: data.info.content
                    };
                    setText((prevState) => {
                        return { ...prevState, values };
                    });
                    setImage(data.info.image);
                }
            })
            .catch((err) => console.log(err));
    }

    function handleTextOnChange(event) {
        const { name, value } = event.target;

        const values = text.values;

        values[name] = value;

        setText((prevState) => {
            return { ...prevState, values };
        });
    }

    function handleOnTextUpdate() {
        const data = { title: text.values.title, content: text.values.content };
        Axios.post("/style/reviewsInColumns/updateText", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setButtonColor("success");
                    setTimeout(() => {
                        setButtonColor("primary");
                    }, 2000);
                }
            })
            .catch((err) => console.log(err));
    }

    function reMapImage(reCallData, file) {
        const data = { file: file, deleteFile: reCallData.deleteFile };
        Axios.post("/style/reviewsInColumns/reMapImage", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    onLoad();
                }
            })
            .catch((err) => console.log(err));
    }

    return (
        <div style={ConfigStyles.body}>
            <br />
            <Row>
                <Col>
                    <div style={ConfigStyles.imageText}>
                        <strong>Image: </strong> {image}
                    </div>
                    <UploadComponent
                        isImage={true}
                        fileType={"n/a"}
                        fileLimit={1}
                        fileSize={8}
                        multiple={false}
                        reCallData={{ deleteFile: image }}
                        directoryPath={"mainStyle/reviewsInColumns"}
                        nextFunction={reMapImage}
                        fileMessage={"Images only"}
                        showFileText
                    />
                    <br />
                    <p style={ConfigStyles.imageText}>
                        <strong>Acticle Title:</strong>
                    </p>
                    <Form.Control type="text" value={text.values.title} maxLength={text.maxLength.title} name="title" onChange={handleTextOnChange} />
                    <div style={ConfigStyles.counter}>
                        {text.values.title.length}/{text.maxLength.title}
                    </div>
                    <br />
                    <p style={ConfigStyles.imageText}>
                        <strong>Acticle Content:</strong>
                    </p>
                    <Form.Control
                        as="textarea"
                        name="content"
                        rows={3}
                        value={text.values.content}
                        maxLength={text.maxLength.content}
                        onChange={handleTextOnChange}
                    />
                    <div style={ConfigStyles.counter}>
                        {text.values.content.length}/{text.maxLength.content}
                    </div>

                    <br />
                    <div style={ConfigStyles.columnRight}>
                        <Button style={ConfigStyles.updateButton} onClick={handleOnTextUpdate} variant={buttonColor}>
                            Update Text
                        </Button>
                    </div>
                </Col>
                <Col>
                    <Image src={`/content/mainStyle/reviewsInColumns/${image}`} style={ConfigStyles.image} />
                </Col>
            </Row>
        </div>
    );
}

export default Config;
