import React, { useState, useEffect, useRef, useCallback } from "react";
import { Button } from "react-bootstrap";
import Axios from "axios";
import DOMPurify from "dompurify";
import HTMLParse from "html-react-parser";

//STYLES
import * as SlideShowStyles from "./styles";

function SlideShow() {
    const [isLoaded, setIsLoaded] = useState(false);
    const [slider1, setSlider1] = useState(0);
    const [slider2, setSlider2] = useState(0);

    const [slider1Data, setSlider1Data] = useState("");
    const [slider2Data, setSlider2Data] = useState("");

    const [slider1Styles, setSlider1Slyles] = useState({});
    const [slider2Styles, setSlider2Slyles] = useState({});

    const [slider1Hover, setSlider1Hover] = useState({});
    const [slider2Hover, setSlider2Hover] = useState({});

    const refScreenWidth = useRef(null);

    const animation = document.getElementById("animation");

    useEffect(() => {
        Axios.post("/style/SPOD_SLIDER_ADMIN/frontEndLoad")
            .then((res) => {
                const data = res.data;

                if (data.error == "null") {
                    if (refScreenWidth.current != null) {
                        for (const slide of data.slides) {
                            slide.hover = false;
                        }

                        setSlider2(refScreenWidth.current.offsetWidth);
                        setSlider1Data(data.slides[0]);
                        setSlider2Data(data.slides[1]);
                        setIsLoaded(true);
                        // setTimeout(() => {
                        //     setIsLoaded(true);
                        //     startAnimation(data.slides, 0);
                        // }, 100);
                    }
                }
            })
            .catch((err) => console.log(err));
    }, []);

    function startAnimation(data, currentData) {
        setSlider1Data(data[currentData]);
        setSlider2Data(data[currentData + 1]);

        setSlider1Slyles({ transition: "all 2s ease-out" });
        setSlider2Slyles({ transition: "all 2s ease-out" });

        setTimeout(() => {
            if (refScreenWidth.current != null) {
                setSlider1(0 - refScreenWidth.current.offsetWidth);

                setSlider2(0);
                setTimeout(() => {
                    ResetSliderWithPhoto(data, currentData + 1);
                }, 2000);
            }
        }, 5000);
    }

    function ResetSliderWithPhoto(data, currentData) {
        if (refScreenWidth.current != null) {
            setSlider1Slyles({});
            setSlider2Slyles({});
            setSlider1Data(data[currentData]);

            let nextData = currentData + 1;
            if (nextData > data.length - 1) {
                nextData = 0;
            }
            setSlider2Data(data[nextData]);

            setSlider1(0);
            setSlider2(refScreenWidth.current.offsetWidth);

            setTimeout(() => {
                RestartAnimation(data, nextData);
            }, 4000);
        }
    }

    function RestartAnimation(data, nextData) {
        if (refScreenWidth.current != null) {
            setSlider1Slyles({ transition: "all 2s ease-out" });
            setSlider2Slyles({ transition: "all 2s ease-out" });

            setTimeout(() => {
                setSlider1(0 - refScreenWidth.current.offsetWidth);

                setSlider2(0);
                setTimeout(() => {
                    ResetSliderWithPhoto(data, nextData);
                }, 2000);
            }, 1000);
        }
    }

    function handleSider1MouseEnter() {}

    function handleSlider1MouseLeave() {}

    function handleSider2MouseEnter() {}

    function handleSlide21MouseLeave() {}

    return (
        <div style={SlideShowStyles.slideShowContainer}>
            <div ref={refScreenWidth} style={SlideShowStyles.body}>
                {isLoaded && (
                    <div>
                        <div
                            style={{
                                ...SlideShowStyles.sliderPanel1,
                                backgroundImage: `url(/content/mainStyle/slider/${slider1Data.picFilename})`,
                                marginLeft: `${slider1}px`,
                                ...slider1Styles
                            }}
                        >
                            {slider1Data.text != "" && (
                                <div style={{ ...SlideShowStyles.sliderInnerPadding, color: slider1Data.textColor }}>
                                    <div style={SlideShowStyles.sliderInside}>
                                        {HTMLParse(DOMPurify.sanitize(slider1Data.text))}
                                        <hr style={{ ...SlideShowStyles.hrLine, backgroundColor: slider1Data.textColor }} />
                                        {slider1Data.showBtn && slider1Data.btnText != "" && (
                                            <div
                                                style={{
                                                    ...SlideShowStyles.buttonStyle,
                                                    color: slider1Data.btnTextColor,
                                                    border: `${slider1Data.btnBorderColor} 1px solid`,
                                                    background: slider1Data.btnBackColor
                                                }}
                                            >
                                                {slider1Data.btnText}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div
                            style={{
                                ...SlideShowStyles.sliderPanel2,
                                backgroundImage: `url(/content/mainStyle/slider/${slider2Data.picFilename})`,
                                marginLeft: `${slider2}px`,
                                ...slider2Styles
                            }}
                        >
                            {slider2Data.text != "" && (
                                <div style={{ ...SlideShowStyles.sliderInnerPadding, color: slider2Data.textColor }}>
                                    <div style={SlideShowStyles.sliderInside}>
                                        {HTMLParse(DOMPurify.sanitize(slider2Data.text))}
                                        <hr style={{ ...SlideShowStyles.hrLine, backgroundColor: slider2Data.textColor }} />
                                        {slider2Data.showBtn && slider2Data.btnText != "" && (
                                            <div
                                                style={{
                                                    ...SlideShowStyles.buttonStyle,
                                                    color: slider2Data.btnTextColor,
                                                    border: `${slider2Data.btnBorderColor} 1px solid`,
                                                    background: slider2Data.btnBackColor
                                                }}
                                            >
                                                {slider2Data.btnText}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default SlideShow;
