import React from "react";

import Slider from "../mainStyle/components/slider/frontEnd/slider";
import SimpleSectionCenteredText from "../mainStyle/components/simpleSectionCenteredText/frontEnd/simpleSectionCentredText";
import HalfPhoto from "../mainStyle/components/halfPhoto//frontEnd/halfPhoto";
import BannerSections from "../mainStyle/components/bannerSections/frontEnd/bannerSections";
import VideoSection from "../mainStyle/components/videoSection/frontEnd/video";
import ReviewsInColumns from "../mainStyle/components/reviewsInColumns/frontEnd/reviewsInColumns";
import PhotoRow from "../mainStyle/components/photoRow/frontEnd/photoRow";
import FourSectionsOneRow from "../mainStyle/components/fourSectionsOneRow/frontEnd/fourSectionsOneRow";
import EventsSlider from "../mainStyle/components/eventsSlider/eventsSlider";
import Footer from "../mainStyle/components/footer/footer";

function HomePage() {
    return (
        <div>
            <Slider />
            <SimpleSectionCenteredText />
            <HalfPhoto />
            <FourSectionsOneRow />
            <BannerSections />
            <VideoSection />
            <EventsSlider />
            <ReviewsInColumns />
            <PhotoRow />
            <Footer />
        </div>
    );
}

export default HomePage;
