import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

//STYLES
import * as NavBarStyles from "./styles";

//IMAGES
import CrossImage from "./images/cross2.png";

function NavBar() {
    const navigate = useNavigate();
    const [navColor, setNavColor] = useState("transparent");
    const [servicesMenuScale, setServicesMenuScale] = useState(0);
    const [servicesMenuPosition, setServicesMenuPosition] = useState({
        x: 0,
        y: 0
    });
    const [triangle, setTriangle] = useState(0);

    const [servicesMenuItems, setServicesMenuItems] = useState([
        {
            title: "Sunday Mass",
            hovering: false
        },
        {
            title: "Second Page",
            hovering: false
        }
    ]);

    const servicesMenu = useRef(null);

    useEffect(() => {
        window.addEventListener("scroll", checkHeight);

        setServicesMenuPosition({
            x: servicesMenu.current.getBoundingClientRect().x,
            y: servicesMenu.current.getBoundingClientRect().y
        });

        return () => {
            window.removeEventListener("scroll", checkHeight);
        };
    }, []);

    function checkHeight() {
        if (window.scrollY > 0) {
            setNavColor("rgba(60, 54, 51,0.5)");
        } else {
            setNavColor("transparent");
        }
    }

    function handleOnMouseEnter() {
        setServicesMenuScale(1);
        setTriangle(180);
    }

    function handleOnMouseLeave() {
        setServicesMenuScale(0);
        setTriangle(0);
    }

    function handleOnMouseEnter_ServiceMenu(id) {
        const menu = { ...servicesMenuItems };

        menu[id].hovering = true;

        setServicesMenuItems(menu);
    }

    function handleOnMouseLeave_ServiceMenu(id) {
        const menu = { ...servicesMenuItems };

        menu[id].hovering = false;

        setServicesMenuItems(menu);
    }

    function handleLinkSecondPageClick() {
        navigate("./secondPage");
    }

    return (
        <div style={{ ...NavBarStyles.body, backgroundColor: navColor }}>
            <Row>
                <Col sm={1} style={NavBarStyles.logoContainer}>
                    <Image src={CrossImage} style={NavBarStyles.logo} />
                </Col>
                <Col sm={2}>
                    <div className="titleFont">St Giles</div>
                </Col>
                <Col
                    ref={servicesMenu}
                    sm={1}
                    style={NavBarStyles.buttonContainer}
                    onMouseEnter={handleOnMouseEnter}
                    onMouseLeave={handleOnMouseLeave}
                >
                    Services<div style={{ ...NavBarStyles.menuTriangle, transform: `rotate(${triangle}deg)` }}></div>
                </Col>
                <Col sm={1} style={NavBarStyles.buttonContainer}>
                    Gallery
                </Col>
                <Col sm={1} style={NavBarStyles.buttonContainer}>
                    What&apos;s On
                </Col>
                <Col sm={1} style={NavBarStyles.buttonContainer}>
                    News
                </Col>
                <Col style={NavBarStyles.buttonContainer}>
                    <i className="fa-solid fa-phone"></i>&nbsp;&nbsp; 01978 355808
                </Col>
                <Col style={NavBarStyles.buttonContainer}>Donate</Col>
            </Row>
            {servicesMenu != null && (
                <div
                    style={{
                        ...NavBarStyles.menu,
                        top: `${servicesMenuPosition.y}px`,
                        left: `${servicesMenuPosition.x}px`,
                        transform: `scale(${servicesMenuScale})`
                    }}
                    onMouseEnter={handleOnMouseEnter}
                    onMouseLeave={handleOnMouseLeave}
                >
                    <div style={NavBarStyles.menuCover}>
                        <div style={NavBarStyles.triangle}></div>
                        <Row>
                            <Col
                                style={servicesMenuItems[0].hovering ? NavBarStyles.subMenuItemHover : NavBarStyles.subMenuItem}
                                onMouseEnter={handleOnMouseEnter_ServiceMenu.bind(this, 0)}
                                onMouseLeave={handleOnMouseLeave_ServiceMenu.bind(this, 0)}
                            >
                                {servicesMenuItems[0].title}
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                style={servicesMenuItems[1].hovering ? NavBarStyles.subMenuItemHover : NavBarStyles.subMenuItem}
                                onMouseEnter={handleOnMouseEnter_ServiceMenu.bind(this, 1)}
                                onMouseLeave={handleOnMouseLeave_ServiceMenu.bind(this, 1)}
                                onClick={handleLinkSecondPageClick}
                            >
                                {servicesMenuItems[1].title}
                            </Col>
                        </Row>
                    </div>
                </div>
            )}

            {/* <Row>
                <Col sm={1}></Col>
                <Col sm={2}></Col>
                <Col>
                    
                </Col>
                <Col></Col>
                <Col></Col>
                <Col></Col>
                <Col></Col>
            </Row> */}
        </div>
    );
}

export default NavBar;
