import React from "react";
import { Image } from "react-bootstrap";

//STYLES
import * as SecondPageStyles from "./styles/secondPage";

function SecondPage() {
    return (
        <div>
            <Image src={"/content/subPagesPhoto/windowCross.jpg"} style={SecondPageStyles.topImage} />
            <div style={SecondPageStyles.body}>
                <p>All sub pages could have a different photo at the top</p>
            </div>
        </div>
    );
}

export default SecondPage;
