import React, { useState } from "react";
import CenterContainer from "../../../../special/wholeSite/centerContainer";
import { useNavigate } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Axios from "axios";

//ACTIONS
import * as UserActions from "../../../../../../store/actions/user";

//STYLES
import * as loginFormStyles from "../../../loginPod/styles/loginForm";
import * as Styles from "../styles/loginForm";

function LoginForm() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [message, setMessage] = useState("");

    const [formState, setFormState] = useState({
        valids: {
            username: false,
            password: false
        },
        touched: {
            username: false,
            password: false
        },
        wholeForm: false
    });

    function goBack() {
        navigate(-1);
    }

    function handleKeyPress(event) {
        if (event.key === "Enter") {
            logIn(event);
        }
    }

    function logIn(event) {
        event.preventDefault();
        if (!formState.wholeForm) {
            switchAllTouches();
        } else {
            const email = document.getElementById("username").value;
            const password = document.getElementById("password").value;

            const data = { email: email, password: password };
            Axios.post("/userLogin/auth/local/user", data)
                .then((res) => {
                    const data = res.data;
                    if (data.message === "Logged in successful") {
                        dispatch(UserActions.UpdateAuth(true));
                        dispatch(UserActions.UpdateID(data.user.id));
                        dispatch(UserActions.UpdateName(data.user.fullName));
                        dispatch(UserActions.UpdateEmail(data.user.email));
                        dispatch(UserActions.UpdateType(data.user.type));
                        dispatch(UserActions.UpdateRequestedPassword(data.user.requestedPassword == "true"));
                        setMessage("");
                        // if (data.user.requestedPassword == "true") {
                        //     console.log("change Password");
                        //     navigate("/membership/changePassword/main");
                        // } else {
                        //     navigate(-1);
                        // }
                        const styleData = { userID: data.user.id };
                        Axios.post("/pods/stylingPortalAdmin/getStyleData", styleData)
                            .then((res) => {
                                const data = res.data;
                                if (data.error == "null") {
                                    navigate(`/style/${data.tag}`);
                                }
                            })
                            .catch((err) => console.log(err));
                    } else {
                        setMessage(data.info);
                        if (data.info === "A Super Admin cannot sign into the front end") {
                            document.getElementById("username").value = "";
                            document.getElementById("password").value = "";
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    function onInputChange(event) {
        const { id, value } = event.target;

        const valids = formState.valids;

        let valid = false;

        if (value.length > 0) {
            valid = true;
        }

        valids[id] = valid;

        let wholeForm = false;

        if (valids.username && valids.password) {
            wholeForm = true;
        }

        setFormState((prevState) => {
            return { ...prevState, valids: valids, wholeForm: wholeForm };
        });
    }

    function handleTouched(event) {
        const { id } = event.target;

        const updatedTouched = { ...formState.touched, [id]: true };

        setFormState((prevState) => {
            return { ...prevState, touched: updatedTouched };
        });
    }

    function switchAllTouches() {
        const newTouches = Object.assign(...Object.keys(formState.touched).map((k) => ({ [k]: true })));
        setFormState((prevState) => {
            return { ...prevState, touched: newTouches };
        });
    }

    return (
        <div style={Styles.body}>
            <p style={Styles.title}>Styling Portal</p>
            <br />
            <div style={Styles.centerContainer}>
                <h1>Login</h1>

                <Form>
                    <Form.Group>
                        <Form.Label>Username:</Form.Label>
                        <Form.Control
                            style={Styles.FP_formTextBox}
                            isInvalid={formState.touched.username && !formState.valids.username ? true : false}
                            onChange={onInputChange}
                            onBlur={handleTouched}
                            type="text"
                            id="username"
                        />
                        <Form.Control.Feedback style={Styles.FP_InputError} type="invalid">
                            Please enter an username
                        </Form.Control.Feedback>
                    </Form.Group>
                    <br />
                    <Form.Group>
                        <Form.Label>Password:</Form.Label>
                        <Form.Control
                            style={Styles.FP_formTextBox}
                            isInvalid={formState.touched.password && !formState.valids.password ? true : false}
                            type="password"
                            id="password"
                            onChange={onInputChange}
                            onBlur={handleTouched}
                            onKeyPress={handleKeyPress}
                        />
                        <Form.Control.Feedback style={Styles.FP_InputError} type="invalid">
                            Please enter a password
                        </Form.Control.Feedback>
                    </Form.Group>
                    {message != "" ? (
                        <div>
                            <br />
                            {message}
                        </div>
                    ) : null}
                    <br />
                    <Button style={loginFormStyles.loginBtns} variant="warning" onClick={logIn}>
                        Login
                    </Button>
                </Form>
            </div>
        </div>
    );
}
export default LoginForm;
