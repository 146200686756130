import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Button } from "react-bootstrap";
import Axios from "axios";

//ACTIONS
import * as GS_navSettingsActions from "../../../../../store/actions/globalSettings/GS_navSettings";

//STYLES
import * as StyleControlStyles from "../styles/styleMainConfiguration";

//ENVIRONMENT STYLE CONFIGS
import StGilesStyleConfiguration from "../../frontEnd/webStyles/stGiles/mainStyle/admin/podRouter";

function StyleMainConfiguration() {
    const dispatch = useDispatch();
    const GS_navSettings = useSelector((state) => state.GS_navSettings);
    const [selectedID, setSelectedID] = useState(2);

    const environments = [
        {
            id: 1,
            name: "High-View Studios",
            tag: "highViewStudios"
        },
        {
            id: 2,
            name: "St Giles",
            tag: "stGiles"
        }
    ];

    useEffect(() => {
        setTimeout(() => {
            dispatch(GS_navSettingsActions.UpdateSelected("Style Configuration"));
            dispatch(GS_navSettingsActions.UpdateStatic(true));
            dispatch(GS_navSettingsActions.UpdateStaticText("Styling Portal - Main Configuration"));
        }, 1);

        const data = { currentAdmin: environments.find((env) => env.id == selectedID).tag };
        Axios.post("/pods/stylingPortalAdmin/updateCurrentAdmin", data)
            .then((res) => {
                const data = res.data;
                if (data.error !== "null") {
                    console.log(data);
                }
            })
            .catch((err) => console.log(err));
    }, []);

    function handleDropdownItemSelected(id) {
        setSelectedID(id);

        const data = { currentAdmin: environments.find((env) => env.id == id).tag };
        Axios.post("/pods/stylingPortalAdmin/updateCurrentAdmin", data)
            .then((res) => {
                const data = res.data;
                console.log(data);
            })
            .catch((err) => console.log(err));
    }

    function handleTest() {
        Axios.post("/style/getInfo")
            .then((res) => {
                console.log(res.data);
            })
            .catch((err) => console.log(err));
    }

    return (
        <div>
            <div style={StyleControlStyles.columnRight}>
                <Dropdown>
                    <Dropdown.Toggle>
                        {selectedID == 0 ? "Select Environment" : environments.find((env) => env.id == selectedID).name}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {environments.map((env, index) => {
                            return (
                                <Dropdown.Item key={index} onClick={handleDropdownItemSelected.bind(this, env.id)}>
                                    {env.name}
                                </Dropdown.Item>
                            );
                        })}
                    </Dropdown.Menu>
                </Dropdown>
                <Button onClick={handleTest}>Test</Button>
            </div>
            <hr style={StyleControlStyles.sectionLine} />

            {selectedID != 0 && (
                <div>
                    <div style={StyleControlStyles.sectionTitle}>{GS_navSettings.title}</div>
                    {environments.find((env) => env.id == selectedID).tag == "stGiles" && <StGilesStyleConfiguration />}
                </div>
            )}
        </div>
    );
}

export default StyleMainConfiguration;
