import React from "react";

function ContactUs() {
    return (
        <div
            style={{
                border: "1px solid black",
                height: "100vh",
                width: "100vw",
                backgroundImage: `url('/content/mainStyle/homeSections/backgroundImages/image6.jpeg')`,
                backgroundSize: "cover"
            }}
        >
            <div
                style={{
                    height: "100vh",
                    backgroundColor: "rgba(0,0,255,0.5)"
                }}
            ></div>
        </div>
    );
}

export default ContactUs;
