export const body = {
    backgroundColor: "#FAF3F3",
    minHeight: "200px"
};

export const image = {
    width: "100%",
    height: "100%"
};

export const textHalf = {
    display: "flex",
    flexDirection: "row",
    flex: "1",
    alignItems: "center"
};

export const donateBtn = {};

export const readMoreBtn = {
    color: "black"
};
