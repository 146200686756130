import React from "react";

//PODS
//--ADMINISTRATOR
import HVSAdminLoginControl from "./administration/HVSLoginForm/loginControl";

//--ADMIN
import StylingPortalAdmin from "./stylingPortal/admin/podRouter";

//--ADMIN-STYLES
import StyleMainConfiguration from "./stylingPortal/admin/files/styleMainConfiguration";

//--FRONTEND

function EnvironmentPodsMap(props) {
    return (
        <div>
            {props.podCode == "loginForm" && <HVSAdminLoginControl />}
            {/* podCode > component */}

            {/* ADMIN */}
            {props.podCode == "CPOD_STYLINGPORTAL_ADMIN" && <StylingPortalAdmin />}

            {props.podCode == "STYLINGCONFIG" && <StyleMainConfiguration />}

            {/* FRONTEND */}
        </div>
    );
}

export function EnvironmentPodsMap_PageViewer(props) {
    return null;
}

export function SegmentedMenus(code) {
    return null;
}

export default EnvironmentPodsMap;
