export const body = {
    height: "200px",
    backgroundColor: "#1B2125"
};

export const title = {
    textAlign: "center"
};

export const linksSection = {
    display: "flex",
    flexDirection: "row",
    flex: "1",
    justifyContent: "center"
};

export const innerLinksSection = {
    paddingTop: "20px",
    width: "70%"
};

export const link = {
    color: "white",
    textAlign: "center",
    fontWeight: "bold",
    cursor: "pointer"
};

export const produced = {
    color: "white",
    textAlign: "center"
};
