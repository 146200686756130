import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Form, Button } from "react-bootstrap";
import Axios from "axios";

//ACTIONS
import * as GS_navSettingsActions from "../../../../../../../../../../store/actions/globalSettings/GS_navSettings";

//STYLES
import * as ConfigStyles from "./styles";

function Config() {
    const dispatch = useDispatch();

    const [data, setData] = useState({
        values: {
            title: "",
            section1: "",
            section2: "",
            section3: "",
            section4: ""
        },
        maxLength: {
            title: 50,
            section: 25
        }
    });
    const [buttonColor, setButtonColor] = useState("primary");

    useEffect(() => {
        dispatch(GS_navSettingsActions.UpdateTitle("Style Configuration - Banner Section"));

        Axios.post("/style/bannerSections/load")
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    const values = {
                        title: data.info.title,
                        section1: data.info.section1,
                        section2: data.info.section2,
                        section3: data.info.section3,
                        section4: data.info.section4
                    };

                    setData((prevState) => {
                        return { ...prevState, values };
                    });
                }
            })
            .catch((err) => console.log(err));
    }, []);

    function handleTextChange(event) {
        const { name, value } = event.target;

        const values = data.values;
        values[name] = value;

        setData((prevState) => {
            return { ...prevState, values };
        });
    }

    function handleUpdate() {
        const updateData = {
            title: data.values.title,
            section1: data.values.section1,
            section2: data.values.section2,
            section3: data.values.section3,
            section4: data.values.section4
        };

        Axios.post("/style/bannerSections/update", updateData)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setButtonColor("success");
                    setTimeout(() => {
                        setButtonColor("primary");
                    }, 2000);
                }
            })
            .catch((err) => console.log(err));
    }

    return (
        <div style={ConfigStyles.body}>
            <div style={ConfigStyles.templateTitle}>Template:</div>
            <br />
            <div style={ConfigStyles.templateBorder}>
                <div style={ConfigStyles.innerSection}>
                    <p style={ConfigStyles.title}>Title</p>
                    <Row>
                        <Col style={ConfigStyles.columns}>1</Col>
                        <Col style={ConfigStyles.columns}>2</Col>
                        <Col style={ConfigStyles.columns}>3</Col>
                        <Col style={ConfigStyles.columns}>4</Col>
                    </Row>
                    <br />
                </div>
            </div>
            <br />
            <Row>
                <Col style={ConfigStyles.textColumns}>
                    Title:
                    <Form.Control type="text" name="title" value={data.values.title} onChange={handleTextChange} />
                </Col>
                <Col></Col>
            </Row>
            <Row>
                <Col style={ConfigStyles.counter}>
                    {data.values.title.length}/{data.maxLength.title}
                </Col>
                <Col></Col>
            </Row>
            <br />
            <Row>
                <Col>
                    <Row>
                        <Col style={ConfigStyles.textColumns}>
                            1:
                            <Form.Control type="text" name="section1" value={data.values.section1} onChange={handleTextChange} />
                        </Col>
                    </Row>
                    <Row>
                        <Col style={ConfigStyles.counter}>
                            {data.values.section1.length}/{data.maxLength.section}
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col style={ConfigStyles.textColumns}>
                            3:
                            <Form.Control type="text" name="section3" value={data.values.section3} onChange={handleTextChange} />
                        </Col>
                    </Row>
                    <Row>
                        <Col style={ConfigStyles.counter}>
                            {data.values.section3.length}/{data.maxLength.section}
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row>
                        <Col style={ConfigStyles.textColumns}>
                            2:
                            <Form.Control type="text" name="section2" value={data.values.section2} onChange={handleTextChange} />
                        </Col>
                    </Row>
                    <Row>
                        <Col style={ConfigStyles.counter}>
                            {data.values.section2.length}/{data.maxLength.section}
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col style={ConfigStyles.textColumns}>
                            4:
                            <Form.Control type="text" name="section4" value={data.values.section4} onChange={handleTextChange} />
                        </Col>
                    </Row>
                    <Row>
                        <Col style={ConfigStyles.counter}>
                            {data.values.section4.length}/{data.maxLength.section}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <br />
            <div style={ConfigStyles.columnRight}>
                <Button style={ConfigStyles.updateButton} onClick={handleUpdate} variant={buttonColor}>
                    Update
                </Button>
            </div>
        </div>
    );
}

export default Config;
