export const body = {
    backgroundColor: "#FAF3F3"
};
export const imageContainer = {
    height: "400px",
    display: "flex",
    flexDirection: "column",
    flex: "1",
    alignItems: "center",
    justifyContent: "center",
    position: "relative"
};

export const image = {
    width: "80%",
    height: "80%"
};

export const sliderHolder = {
    overflow: "hidden",
    position: "absolute",
    height: "200px",
    width: "350px",
    bottom: "25px",
    left: "50px",
    backgroundColor: "red",
    borderRadius: "25px"
};

export const slider1 = {
    // border: "solid 1px black",
    width: "100%",
    height: "100%",
    position: "absolute",
    display: "flex",
    flexDirection: "row",
    flex: "1",
    alignItems: "center",
    justifyContent: "center"
};

export const sliderText = {
    fontSize: "30px",
    textAlign: "center",
    color: "white",
    padding: "5px"
};

export const slider2 = {
    // border: "solid 1px black",
    width: "100%",
    height: "100%",
    position: "absolute",
    display: "flex",
    flexDirection: "row",
    flex: "1",
    alignItems: "center",
    justifyContent: "center"
};

export const articleOuter = {
    display: "flex",
    flexDirection: "rows",
    flex: "1",
    alignItems: "center",
    justifyContent: "center"
};

export const articleInner = {
    padding: "10px"
};
