export const body = {
    minHeight: "300px",
    backgroundColor: "#1B2125",
    display: "flex",
    flexDirection: "row",
    flex: "1",
    alignItems: "center",
    justifyContent: "center"
};

export const title = {
    color: "white"
};

export const innerSection = {
    width: "70%"
};

export const columnText = {
    color: "white",
    textAlign: "center",
    fontSize: "20px",
    fontWeight: "bold"
};
