export const body = {
    paddingRight: "30px",
    paddingTop: "20px"
};

export const columns = {
    textAlign: "center",
    fontSize: "1.5rem"
};

export const templateTitle = {
    fontSize: "1.5rem"
};

export const templateBorder = {
    border: "1px solid black",
    display: "flex",
    flex: "1",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
};

export const innerSection = {
    width: "70%"
};

export const title = {
    fontSize: "1.5rem"
};

export const textColumns = {
    display: "flex",
    flex: "1",
    flexDirection: "row",
    alignItems: "center",
    columnGap: "10px"
};

export const counter = {
    color: "red",
    textAlign: "right"
};

export const columnRight = {
    textAlign: "right"
};

export const updateButton = {
    transition: "all 0.2s ease-out"
};
