export const body = {
    paddingRight: "30px"
};

export const columnRight = {
    textAlign: "right"
};

export const counter = {
    textAlign: "right",
    color: "red"
};

export const updateButton = {
    transition: "all 0.2s ease-out"
};
