export const columnRight = {
    textAlign: "right",
    paddingRight: "30px"
};

export const sectionLine = {
    height: "2px",
    backgroundColor: "black"
};

export const sectionTitle = {
    textAlign: "center",
    fontSize: "2rem",
    fontWeight: "bold"
};
