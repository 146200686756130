export const body = {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
};

export const navButton = {
    backgroundColor: "white",
    marginTop: "5px",
    marginBottom: "5px",
    paddingTop: "5px",
    paddingBottom: "5px",
    paddingRight: "10px",
    paddingLeft: "10px",
    fontSize: "20px",
    borderRadius: "10px 0 0 10px",
    cursor: "pointer",
    width: "220px"
};

export const navButtonHover = {
    backgroundColor: "white",
    marginTop: "5px",
    marginBottom: "5px",
    paddingTop: "5px",
    paddingBottom: "5px",
    paddingRight: "10px",
    paddingLeft: "10px",
    fontSize: "20px",
    borderRadius: "10px 0 0 10px",
    cursor: "pointer",
    fontWeight: "bold"
};
