import React, { useState, useEffect } from "react";
import Axios from "axios";

//STYLES
import * as Styles from "./styles";

function SimpleSectionCenteredText() {
    const [text, setText] = useState("");

    useEffect(() => {
        Axios.post("/style/simpleSectionCenteredText/load")
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setText(data.text);
                }
            })
            .catch((err) => console.log(err));
    }, []);

    return (
        <div style={Styles.body}>
            <div style={Styles.innerText}>{text}</div>
        </div>
    );
}

export default SimpleSectionCenteredText;
