import React, { useState } from "react";

//STYLES
import * as NavBarStyles from "./styles";

function NavBar(props) {
    const [hover, setHover] = useState({
        home: false,
        meetTheTeam: false,
        servicesAndEvents: false,
        community: false,
        links: false,
        contactUs: false
    });

    function handleMouseEnter(event) {
        const { id } = event.target;

        setHover((prevState) => {
            return { ...prevState, [id]: true };
        });
    }

    function handleMouseLeave(event) {
        const { id } = event.target;

        setHover((prevState) => {
            return { ...prevState, [id]: false };
        });
    }

    return (
        <div style={NavBarStyles.body}>
            <div
                style={hover.home ? NavBarStyles.navButtonHover : NavBarStyles.navButton}
                id="home"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={() => props.setSitePosition(0)}
            >
                Home
            </div>
            <div
                style={hover.meetTheTeam ? NavBarStyles.navButtonHover : NavBarStyles.navButton}
                id="meetTheTeam"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={() => props.setSitePosition(-100)}
            >
                Meet The Team
            </div>
            <div
                style={hover.servicesAndEvents ? NavBarStyles.navButtonHover : NavBarStyles.navButton}
                id="servicesAndEvents"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={() => props.setSitePosition(-200)}
            >
                Services and Events
            </div>
            <div
                style={hover.community ? NavBarStyles.navButtonHover : NavBarStyles.navButton}
                id="community"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={() => props.setSitePosition(-300)}
            >
                Community
            </div>
            <div
                style={hover.links ? NavBarStyles.navButtonHover : NavBarStyles.navButton}
                id="links"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={() => props.setSitePosition(-400)}
            >
                Links
            </div>
            <div
                style={hover.contactUs ? NavBarStyles.navButtonHover : NavBarStyles.navButton}
                id="contactUs"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={() => props.setSitePosition(-500)}
            >
                Contact Us
            </div>
        </div>
    );
}

export default NavBar;
