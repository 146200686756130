import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import Axios from "axios";

//STYLES
import * as PhotoRowStyles from "./styles";

function PhotoRow() {
    const photo = useRef(null);
    const [height, setHeight] = useState(0);

    const [photos, setPhotos] = useState([
        {
            image: "personTemp-brown.png",
            name: "",
            position: "",
            startPoint: 0,
            currentPoint: 0,
            endPoint: 0,
            opacity: 0
        },
        {
            image: "personTemp-brown.png",
            name: "",
            position: "",
            startPoint: 0,
            currentPoint: 0,
            endPoint: 0,
            opacity: 0
        },
        {
            image: "personTemp-brown.png",
            name: "",
            position: "",
            startPoint: 0,
            currentPoint: 0,
            endPoint: 0,
            opacity: 0
        },
        {
            image: "personTemp-brown.png",
            name: "",
            position: "",
            startPoint: 0,
            currentPoint: 0,
            endPoint: 0,
            opacity: 0
        }
    ]);

    useEffect(() => {
        Axios.post("/style/photoRow/load")
            .then((res) => {
                const responseData = res.data;

                const newPhotoSlots = [];

                for (const [index, photo] of photos.entries()) {
                    const tempPhotoSlot = { ...photo };

                    tempPhotoSlot.image = responseData.info[`section${index + 1}Image`];
                    tempPhotoSlot.name = responseData.info[`section${index + 1}Name`];
                    tempPhotoSlot.position = responseData.info[`section${index + 1}Position`];

                    newPhotoSlots.push(tempPhotoSlot);
                }
                setPhotos(newPhotoSlots);
                reArrangeSections(newPhotoSlots);
            })
            .catch((err) => console.log(err));
    }, []);

    function reArrangeSections(photos) {
        setTimeout(() => {
            const height = photo.current.offsetWidth + 40;
            setHeight(height);

            const newPhotoSlots = [];
            for (const [index, photo] of photos.entries()) {
                const headingHeight = document.getElementById(`photo${index}Heading`).offsetHeight;
                const textHeight = document.getElementById(`photo${index}Text`).offsetHeight;

                const fullHeight = headingHeight + textHeight;
                console.log(fullHeight);
                const tempPhotoSlot = { ...photo };

                tempPhotoSlot.endPoint = height - fullHeight;
                tempPhotoSlot.startPoint = height - headingHeight;
                tempPhotoSlot.currentPoint = height - headingHeight;

                newPhotoSlots.push(tempPhotoSlot);
            }
            console.log(newPhotoSlots);
            setPhotos(newPhotoSlots);
        }, 10);
    }

    function handleOnMouseEnter(id) {
        const photosTemp = { ...photos };
        photosTemp[id].currentPoint = photosTemp[id].endPoint;
        photosTemp[id].opacity = 1;

        setPhotos(photosTemp);
    }

    function handleOnMouseLeave(id) {
        const photosTemp = { ...photos };
        photosTemp[id].currentPoint = photosTemp[id].startPoint;
        photosTemp[id].opacity = 0;

        setPhotos(photosTemp);
    }

    return (
        <div style={PhotoRowStyles.body}>
            <Row>
                <Col
                    ref={photo}
                    style={{
                        ...PhotoRowStyles.image,
                        height: `${height}px`,
                        backgroundImage: `url('/content/mainStyle/photoRow/${photos[0].image}')`
                    }}
                    onMouseEnter={handleOnMouseEnter.bind(this, 0)}
                    onMouseLeave={handleOnMouseLeave.bind(this, 0)}
                >
                    <div id="photo0Heading" style={{ ...PhotoRowStyles.heading, marginTop: `${photos[0].currentPoint}px` }}>
                        <h1>{photos[0].name}</h1>
                    </div>
                    <div id="photo0Text" style={{ ...PhotoRowStyles.text, opacity: photos[0].opacity }}>
                        {photos[0].position}
                    </div>
                </Col>
                <Col
                    style={{
                        ...PhotoRowStyles.image,
                        height: `${height}px`,
                        backgroundImage: `url('/content/mainStyle/photoRow/${photos[1].image}')`
                    }}
                    onMouseEnter={handleOnMouseEnter.bind(this, 1)}
                    onMouseLeave={handleOnMouseLeave.bind(this, 1)}
                >
                    <div id="photo1Heading" style={{ ...PhotoRowStyles.heading, marginTop: `${photos[1].currentPoint}px` }}>
                        <h1>{photos[1].name}</h1>
                    </div>
                    <div id="photo1Text" style={{ ...PhotoRowStyles.text, opacity: photos[1].opacity }}>
                        {photos[1].position}
                    </div>
                </Col>
                <Col
                    style={{
                        ...PhotoRowStyles.image,
                        height: `${height}px`,
                        backgroundImage: `url('/content/mainStyle/photoRow/${photos[2].image}')`
                    }}
                    onMouseEnter={handleOnMouseEnter.bind(this, 2)}
                    onMouseLeave={handleOnMouseLeave.bind(this, 2)}
                >
                    <div id="photo2Heading" style={{ ...PhotoRowStyles.heading, marginTop: `${photos[2].currentPoint}px` }}>
                        <h1>{photos[2].name}</h1>
                    </div>
                    <div id="photo2Text" style={{ ...PhotoRowStyles.text, opacity: photos[2].opacity }}>
                        {photos[2].position}
                    </div>
                </Col>
                <Col
                    style={{
                        ...PhotoRowStyles.image,
                        height: `${height}px`,
                        backgroundImage: `url('/content/mainStyle/photoRow/${photos[3].image}')`
                    }}
                    onMouseEnter={handleOnMouseEnter.bind(this, 3)}
                    onMouseLeave={handleOnMouseLeave.bind(this, 3)}
                >
                    <div id="photo3Heading" style={{ ...PhotoRowStyles.heading, marginTop: `${photos[3].currentPoint}px` }}>
                        <h1>{photos[3].name}</h1>
                    </div>
                    <div id="photo3Text" style={{ ...PhotoRowStyles.text, opacity: photos[3].opacity }}>
                        {photos[3].position}
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default PhotoRow;
