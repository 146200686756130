import * as Colors from "../../../../globalStyles/administratorColors";

export const body = {
    paddingTop: "20px",
    paddingRight: "30px"
};

export const columnRight = {
    textAlign: "right"
};

export const cardStyle = {
    backgroundColor: Colors.primary,
    color: "white",
    cursor: "pointer"
};
