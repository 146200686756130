import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { isBrowser } from "react-device-detect";
import { Routes, Route } from "react-router-dom";

import "./styles/customNavCSSStyles.css";

//STYLES
import * as UserRouteSystemStyles from "./styles/userRouteSystem";

//COMPONENTS
import SiteOffline from "../../../../../../../administration/pods/siteOffline/files/siteOffline";
import AdminBanner from "../../../../../../../administration/pods/adminBanner/adminBanner";

//STYLING COMPONENTS
import BrowseNavBar from "../style/navBar/browserNavBar";
import MobileNavBar from "../style/navBar/mobileNavBar";

function UserRouteSystem() {
    const user = useSelector((state) => state.user);

    const [isLoaded, setIsLoaded] = useState(false);
    const [isOffline, setIsOffline] = useState(false);

    useEffect(() => {
        setIsLoaded(true);
    }, []);

    function offlineUserCheck(role) {
        if (role == "modifier" || role == "registered" || role == "") {
            return false;
        } else {
            return true;
        }
    }

    return (
        <div>
            {isLoaded && (
                <div>
                    {isOffline && !offlineUserCheck(user.type) ? (
                        <div>
                            <SiteOffline />
                        </div>
                    ) : (
                        <div style={UserRouteSystemStyles.body}>
                            {user.adminSignedIn ? <AdminBanner /> : null}
                            {isBrowser ? <BrowseNavBar /> : <MobileNavBar />}
                            <Routes>
                                {/* <Route path="/" exact element={<PageViewer pageID={0} />} />
                                <Route path="/about" element={<PageViewer pageID={1} />} /> */}
                            </Routes>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default UserRouteSystem;
