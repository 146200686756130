import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Image, Button } from "react-bootstrap";
import Axios from "axios";

//STYLES
import * as ReviewsInColumnsStyles from "./styles";

function ReviewsInColumns() {
    const [article, setArticle] = useState({
        title: "",
        content: ""
    });
    const [image, setImage] = useState("");

    const [reviews, setReviews] = useState([
        "This is a nice and plesant church",
        "I have been coming here all my life and loved every second on it",
        "The worship here is first class and can't think of being anywhere else"
    ]);
    const [viewWidth, setViewWidth] = useState(0);

    const [slider1Position, setSlider1Position] = useState(0);
    const [slider2Position, setSlider2Position] = useState(0);

    const [slider1Review, setSlider1Review] = useState(0);
    const [slider2Review, setSlider2Review] = useState(1);

    const [slider1Styles, setSlider1Slyles] = useState({});
    const [slider2Styles, setSlider2Slyles] = useState({});

    const [currentReview, setCurrentReview] = useState(0);

    const view = useRef(null);

    useEffect(() => {
        setViewWidth(view.current.offsetWidth);
        setSlider2Position(view.current.offsetWidth);

        setTimeout(() => {
            Axios.post("/style/reviewsInColumns/load")
                .then((res) => {
                    const data = res.data;
                    if (data.error == "null") {
                        setArticle({
                            title: data.info.title,
                            content: data.info.content
                        });
                        setImage(data.info.image);
                        startAnimation(0);
                    }
                })
                .catch((err) => console.log(err));
        }, 100);
    }, []);

    function startAnimation(currentReview) {
        setSlider1Review(0);
        setSlider2Review(1);

        setSlider1Slyles({ transition: "all 2s ease-out" });
        setSlider2Slyles({ transition: "all 2s ease-out" });

        setCurrentReview(currentReview + 1);

        setTimeout(() => {
            if (view.current != null) {
                setSlider1Position(0 - view.current.offsetWidth);

                setSlider2Position(0);
                setTimeout(() => {
                    ResetSliderWithPhoto(currentReview + 1);
                }, 2000);
            }
        }, 5000);
    }

    function ResetSliderWithPhoto(currentReview) {
        if (view.current != null) {
            setSlider1Slyles({});
            setSlider2Slyles({});
            setSlider1Review(currentReview);
            setSlider1Position(0);
            setSlider2Position(view.current.offsetWidth);

            setTimeout(() => {
                RestartAnimation(currentReview);
            }, 4000);
        }
    }

    function RestartAnimation(currentReview) {
        if (view.current != null) {
            let cReview = currentReview + 1;
            if (cReview > reviews.length - 1) {
                cReview = 0;
            }
            setSlider2Review(cReview);

            setSlider1Slyles({ transition: "all 2s ease-out" });
            setSlider2Slyles({ transition: "all 2s ease-out" });

            setCurrentReview(cReview);

            setTimeout(() => {
                setSlider1Position(0 - view.current.offsetWidth);

                setSlider2Position(0);
                setTimeout(() => {
                    ResetSliderWithPhoto(cReview);
                }, 2000);
            }, 1000);
        }
    }

    return (
        <div style={ReviewsInColumnsStyles.body}>
            <Row>
                <Col style={ReviewsInColumnsStyles.articleOuter}>
                    <div style={ReviewsInColumnsStyles.articleInner}>
                        <h1>{article.title}</h1>
                        <p>{article.content}</p>
                        <Button>Click Here</Button>
                    </div>
                </Col>
                <Col style={ReviewsInColumnsStyles.imageContainer}>
                    <Image src={`/content/mainStyle/reviewsInColumns/${image}`} style={ReviewsInColumnsStyles.image} />
                    <div ref={view} style={ReviewsInColumnsStyles.sliderHolder}>
                        <div style={{ ...ReviewsInColumnsStyles.slider1, ...slider1Styles, marginLeft: slider1Position }}>
                            <div style={ReviewsInColumnsStyles.sliderText}>
                                <i className="fa-solid fa-quote-left"></i>
                                {reviews[slider1Review]}
                                <i className="fa-solid fa-quote-right"></i>
                            </div>
                        </div>
                        <div style={{ ...ReviewsInColumnsStyles.slider2, ...slider2Styles, marginLeft: slider2Position }}>
                            <div style={ReviewsInColumnsStyles.sliderText}>
                                <i className="fa-solid fa-quote-left"></i>
                                {reviews[slider2Review]}
                                <i className="fa-solid fa-quote-right"></i>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default ReviewsInColumns;
