import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Axios from "axios";

//STYLES
import * as BannerSectionsStyles from "./styles";

function BannerSections() {
    const [data, setData] = useState({
        title: "",
        section1: "",
        section2: "",
        section3: "",
        section4: ""
    });
    useState(() => {
        Axios.post("/style/bannerSections/load")
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    const newData = {
                        title: data.info.title,
                        section1: data.info.section1,
                        section2: data.info.section2,
                        section3: data.info.section3,
                        section4: data.info.section4
                    };

                    setData(newData);
                }
            })
            .catch((err) => console.log(err));
    }, []);

    return (
        <div style={BannerSectionsStyles.body}>
            <div style={BannerSectionsStyles.innerSection}>
                <h1 style={BannerSectionsStyles.title}>{data.title.toUpperCase()}</h1>
                <br />
                <Row>
                    <Col style={BannerSectionsStyles.columnText}>{data.section1}</Col>
                    <Col style={BannerSectionsStyles.columnText}>{data.section2}</Col>
                    <Col style={BannerSectionsStyles.columnText}>{data.section3}</Col>
                    <Col style={BannerSectionsStyles.columnText}>{data.section4}</Col>
                </Row>
            </div>
        </div>
    );
}

export default BannerSections;
