import React, { useState, useEffect, useRef } from "react";
import { Image } from "react-bootstrap";

//STYLES
import * as EventsSliderStyles from "./styles";

function EventsSlider() {
    const outerContainer = useRef(null);
    const [eventSlotWidth, setEventSlotWidth] = useState(0);

    const [slot1Position, setSlot1Position] = useState(0);
    const [slot2Position, setSlot2Position] = useState(0);
    const [slot3Position, setSlot3Position] = useState(0);
    const [slot4Position, setSlot4Position] = useState(0);

    const [eventSlot1, setEventSlot1] = useState(0);
    const [eventSlot2, setEventSlot2] = useState(1);
    const [eventSlot3, setEventSlot3] = useState(2);
    const [eventSlot4, setEventSlot4] = useState(3);

    const [transitionStyle, setTransitionStyle] = useState({});

    const [data, setData] = useState([
        {
            title: "24hr Worship",
            image: "/content/mainStyle/eventSlider/worshiping.jpeg",
            time: "6am Start"
        },
        {
            title: "Leaders Event",
            image: "/content/mainStyle/eventSlider/leaders.jpeg",
            time: "05/05/2024 @ 6pm"
        },
        {
            title: "Good Friday",
            image: "/content/mainStyle/eventSlider/easter.jpeg",
            time: "11am start"
        },
        {
            title: "Easter Hunt",
            image: "/content/mainStyle/eventSlider/eggHunt.jpeg",
            time: "Easter Monday @ 2pm"
        },
        {
            title: "Messy Church",
            image: "/content/mainStyle/eventSlider/messyChurch.png",
            time: "Third Sunday @ 5pm"
        }
    ]);

    useEffect(() => {
        if (outerContainer.current != null) {
            const slotWidth = (outerContainer.current.offsetWidth / 100) * 30;
            setEventSlotWidth(slotWidth);

            setSlot2Position(slotWidth);
            setSlot3Position(slotWidth * 2);
            setSlot4Position(slotWidth * 3);

            setTimeout(() => {
                setTransitionStyle({ transition: "all 2s ease-out" });
                setSlot1Position(0 - slotWidth);
                setSlot2Position(0);
                setSlot3Position(slotWidth);
                setSlot4Position(slotWidth * 2);
                setTimeout(() => {
                    loadNextEvents(0);
                }, 2000);
            }, 3000);
        }
    }, []);

    function loadNextEvents(currentEvent) {
        if (outerContainer.current != null) {
            let cEvent = currentEvent;
            const newEvents = [];
            for (let i = 0; i < 4; i++) {
                if (cEvent < data.length - 1) {
                    cEvent = cEvent + 1;
                    newEvents.push(cEvent);
                } else {
                    cEvent = 0;
                    newEvents.push(cEvent);
                }
            }

            setEventSlot1(newEvents[0]);
            setEventSlot2(newEvents[1]);
            setEventSlot3(newEvents[2]);
            setEventSlot4(newEvents[3]);

            setTransitionStyle({});

            const slotWidth = (outerContainer.current.offsetWidth / 100) * 30;
            setSlot1Position(0);
            setSlot2Position(slotWidth);
            setSlot3Position(slotWidth * 2);
            setSlot4Position(slotWidth * 3);

            setTimeout(() => {
                const nextEvent = currentEvent + 1;
                if (nextEvent > data.length - 1) {
                    RestartAnimation(0);
                } else {
                    RestartAnimation(nextEvent);
                }
            }, 3000);
        }
    }

    function RestartAnimation(currentEvent) {
        if (outerContainer.current != null) {
            const slotWidth = (outerContainer.current.offsetWidth / 100) * 30;

            setTransitionStyle({ transition: "all 2s ease-out" });
            setSlot1Position(0 - slotWidth);
            setSlot2Position(0);
            setSlot3Position(slotWidth);
            setSlot4Position(slotWidth * 2);
            setTimeout(() => {
                loadNextEvents(currentEvent);
            }, 2000);
        }
    }

    return (
        <div style={EventsSliderStyles.body}>
            <h1>
                <strong>Events</strong>
            </h1>{" "}
            <br />
            <div ref={outerContainer} style={EventsSliderStyles.outerSection}>
                <div style={{ ...EventsSliderStyles.slideshowContainer, width: `${eventSlotWidth * 3}px` }}>
                    <div
                        style={{
                            ...EventsSliderStyles.eventSlot,
                            ...transitionStyle,
                            width: `${eventSlotWidth}px`,
                            marginLeft: `${slot1Position}px`
                        }}
                    >
                        <div style={EventsSliderStyles.smallContainer}>
                            <strong>{data[eventSlot1].title}</strong>
                        </div>
                        <div style={EventsSliderStyles.imageContainer}>
                            <Image src={data[eventSlot1].image} style={EventsSliderStyles.image} />
                        </div>
                        <div style={EventsSliderStyles.smallContainer}>{data[eventSlot1].time}</div>
                    </div>
                    <div
                        style={{
                            ...EventsSliderStyles.eventSlot,
                            ...transitionStyle,
                            width: `${eventSlotWidth}px`,
                            marginLeft: `${slot2Position}px`
                        }}
                    >
                        <div style={EventsSliderStyles.smallContainer}>
                            <strong>{data[eventSlot2].title}</strong>
                        </div>
                        <div style={EventsSliderStyles.imageContainer}>
                            <Image src={data[eventSlot2].image} style={EventsSliderStyles.image} />
                        </div>
                        <div style={EventsSliderStyles.smallContainer}>{data[eventSlot2].time}</div>
                    </div>
                    <div
                        style={{
                            ...EventsSliderStyles.eventSlot,
                            ...transitionStyle,
                            width: `${eventSlotWidth}px`,
                            marginLeft: `${slot3Position}px`
                        }}
                    >
                        <div style={EventsSliderStyles.smallContainer}>
                            <strong>{data[eventSlot3].title}</strong>
                        </div>
                        <div style={EventsSliderStyles.imageContainer}>
                            <Image src={data[eventSlot3].image} style={EventsSliderStyles.image} />
                        </div>
                        <div style={EventsSliderStyles.smallContainer}>{data[eventSlot3].time}</div>
                    </div>
                    <div
                        style={{
                            ...EventsSliderStyles.eventSlot,
                            ...transitionStyle,
                            width: `${eventSlotWidth}px`,
                            marginLeft: `${slot4Position}px`
                        }}
                    >
                        <div style={EventsSliderStyles.smallContainer}>
                            <strong>{data[eventSlot4].title}</strong>
                        </div>
                        <div style={EventsSliderStyles.imageContainer}>
                            <Image src={data[eventSlot4].image} style={EventsSliderStyles.image} />
                        </div>
                        <div style={EventsSliderStyles.smallContainer}>{data[eventSlot4].time}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EventsSlider;
