import * as Colors from "../../../../../../../globalStyles/environmentColors";

// export const body = {
//     minHeight: "100vh",
//     backgroundColor: Colors.background
// };

export const mainBody = {
    // paddingTop: "20px",
    // textAlign: "center"
};

export const mainImage = {
    height: "600px",
    width: "100vw"
};

export const slideShowContainer = {
    width: "1200px",
    height: "500px"
    // display: "flex",
    // flexDirection: "row",
    // flex: "1"
};
