export const body = {
    overflow: "hidden",
    width: "100%",
    height: "100%",
    minWidth: "0px",
    // display: "flex",
    // flexDirection: "row",
    // // flex: "1",
    position: "relative"
};

export const sliderPanel1 = {
    backgroundSize: "cover",
    height: "100%",
    width: "100%",
    position: "absolute"
};

export const sliderPanel2 = {
    backgroundSize: "cover",
    height: "100%",
    width: "100%",
    position: "absolute"
};

export const sliderInnerPadding = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: "100%"
};

export const sliderInside = {
    fontWeight: "bold",
    fontSize: "2rem"
};

export const hrLine = {
    border: "none",
    height: "10px",
    opacity: "1"
};

export const buttonStyle = {
    padding: "10px",
    borderRadius: "10px",
    cursor: "pointer",
    fontSize: "1rem",
    display: "inline-block"
};

//MAIN CONTAINER
export const slideShowContainer = {
    width: "80vw",
    height: "80vh"
};
